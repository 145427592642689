<template>
  <transition name="dropdown" appear>
    <div class="container-xs" style="display: flex; flex-direction: column">
      <img
        class="sticker"
        style="align-self: center"
        :src="$image(display.imageUrl)"
        alt="Sticker"
      />
      <break style="margin-top: -18px" />
      <div
        style="
          display: grid;
          gap: 2rem;
          justify-items: center;
          text-align: center;
        "
      >
        <p class="title">{{ display.title }}</p>
        <p class="received">{{ display.receivedAt }}</p>
        <p class="description">{{ display.description }}</p>
        <p class="code">{{ display.code }}</p>
        <x-close-button style="margin-top: 1rem" />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import { date } from '../../other/utils/helpers/date.js'

export default {
  name: 'Profile',
  computed: {
    ...mapState('StickerModule', ['activeSticker']),
    display() {
      const { title, acquiredAt, description, imageUrl } = this.activeSticker
      const acquisitionDate = date.format('-d -ms -y', acquiredAt)
      return {
        title,
        description,
        imageUrl,
        receivedAt: `${this.$t('profile.received')} ${acquisitionDate}`,
        code: `${this.$t('profile.use-code')} ${title} ${this.$t(
          'profile.10-off',
        )}`,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.title {
  font-family: GTSuper;
  font-weight: bold;
  font-size: 2.75rem;
}
.received {
  text-transform: uppercase;
  font-size: 0.825rem;
  letter-spacing: 0.04em;
}
.description {
  font-size: 1.0625rem;
  line-height: 165%;
  letter-spacing: -0.02em;
}
.code {
  font-style: italic;
  font-size: 0.825rem;
  letter-spacing: -0.01em;
}
.sticker {
  transform: rotate(350deg);
  max-width: 350px;
  object-fit: contain;
  object-position: center;
}
</style>
